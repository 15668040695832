import(/* webpackMode: "eager" */ "/workspace/apps/musiclocal/musiclocal/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/workspace/apps/musiclocal/musiclocal/components/WebVitals/WebVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/workspace/libs/client/musiclocal-react/src/lib/components/ProgressBar/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/libs/third-party/shadcn-ui/src/components/Toast/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_react@19.0.0-rc-fb9a90fa48-20240614/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_react@19.0.0-rc-fb9a90fa48-20240614/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_react@19.0.0-rc-fb9a90fa48-20240614/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-202406_ggh6nezc2wgoe6uin5uhhnfhrm/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-202406_ggh6nezc2wgoe6uin5uhhnfhrm/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"weight\":\"500\"}],\"variableName\":\"fontSans\"}");
